import Image from 'next/image'
import { storyblokEditable } from '@storyblok/react'
import cn from '@utils/helpers/cn'
import { useSelector } from 'react-redux'

const StoryblokImage = ({ blok }) => {
  return (
    <Image
      src={blok.image.filename}
      width={blok.width}
      height={blok.height}
      alt={blok.image.alt}
      className={cn(blok.class)}
      priority={blok.Priority}
      quality={75}
      placeholder={
        blok.image.filename.endsWith('.svg') ||
        (blok.width && blok.width < 40) ||
        (blok.height && blok.height < 40)
          ? 'empty'
          : 'blur'
      }
      blurDataURL="/logo_placeholder.png"
      {...storyblokEditable(blok)}
    />
  )
}

const MuiImage = ({ blok }) => {
  const { isMobile } = useSelector((state) => state.ui)
  const mobileImage =
    blok.class.includes('!block') && blok.class.includes('lg:!hidden')
  const desktopImage =
    blok.class.includes('!hidden') && blok.class.includes('lg:!block')
  if (blok.image?.filename) {
    if (mobileImage || desktopImage) {
      if (isMobile && mobileImage) {
        return <StoryblokImage blok={blok} />
      } else if (!isMobile && desktopImage) {
        return <StoryblokImage blok={blok} />
      }
      return null
    }
    return <StoryblokImage blok={blok} />
  }
  return null
}

export default MuiImage
